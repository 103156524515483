import { IMenuItem } from "./IMenuItem";
import BrushIcon from '@mui/icons-material/Brush';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { CSSProperties } from "styled-components";

const iconStyle: CSSProperties = {
    position: 'absolute',
    left: '0vh',
    marginLeft: '1vh',
    color: 'gray'
}

const MENU: IMenuItem[] = [
    {
        id: 1,
        img: require('./img/plasma-plaquetas.jpg'),
        text: 'Descubre nuestro tratamiento de plasma rico en plaquetas, ideal para regenerar la piel y mejorar su textura. Este tratamiento promueve la producción natural de colágeno, devolviendo a tu piel un aspecto joven y radiante.',
        title: 'PLASMA RICOS EN PLAQUETAS',
        to: '/plasma',
        icon: <BrushIcon style={iconStyle} />
    },
    {
        id: 2,
        img: require('./img/sueroterapia.jpg'),
        text: 'Recupera la vitalidad de tu cuerpo con nuestra sueroterapia. Un tratamiento ideal para rehidratar y nutrir desde el interior, ayudando a revitalizar tu cuerpo y mente. ¡Siente la energía renovada y mejora tu bienestar!',
        title: 'SUEROTERAPIA',
        to: '/serumtherapy',
        icon: <StorefrontIcon style={iconStyle} />
    },
    {
        id: 3,
        img: require('./img/limpieza-facial-profunda.jpg'),
        text: 'Elimina las impurezas y consigue una piel más sana con nuestra limpieza facial profunda. Un tratamiento que dejará tu piel limpia, suave y rejuvenecida, ayudando a reducir poros y mejorar la textura de la piel.',
        title: 'LIMPIEZA FACIAL PROFUNDA',
        to: '/limpieza',
        icon: <StorefrontIcon style={iconStyle} />
    },
    {
        id: 4,
        img: require('./img/babyglow.jpg'),
        text: 'Rejuvenece tu piel con nuestro tratamiento BabyGlow, que te dará una apariencia fresca y radiante. Este procedimiento hidrata profundamente y mejora la luminosidad natural de la piel.',
        title: 'BABYGLOW',
        to: '/babyglow',
        icon: <StorefrontIcon style={iconStyle} />
    },
    {
        id: 5,
        img: require('./img/hidrolipoclasia.jpg'),
        text: 'Disminuye la grasa localizada con nuestra hidrolipoclasia. Este tratamiento no invasivo ayuda a moldear tu figura y mejorar la apariencia de tu piel, logrando un contorno más definido.',
        title: 'HIDROLIPOCLASIA',
        to: '/hydrohypoclasia',
        icon: <StorefrontIcon style={iconStyle} />
    }
]

export default MENU;
