import React from 'react';
import { Typography, Container } from '@mui/material';
import { BackButton } from '../common/BackButton';
import { Img } from 'react-image';
import PLASMAIMAGES from './data';

const PlateletTherapyProcess = () => {


  return (
    <Container>
      <BackButton url='/' />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>PLASMA RICOS EN PLAQUETAS</h3>
      </Typography>
      <Img alt={PLASMAIMAGES[0].keywords} style={{ width: '100%', borderRadius: '2%' }} src={PLASMAIMAGES[0].img} />

      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La sueroterapia con plaquetas, también conocida como PRP (Plasma Rico en Plaquetas), es un tratamiento que utiliza factores de crecimiento extraídos de la sangre del propio paciente.
      </Typography>
      <Typography variant="h6" gutterBottom>
        ¿Cómo se realiza el procedimiento?
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        Primero, se extrae una pequeña cantidad de sangre del paciente. Luego, se centrifuga para separar el plasma rico en plaquetas. Finalmente, este plasma se inyecta en las áreas específicas donde se desea estimular la regeneración celular.
      </Typography>
      <Img alt={PLASMAIMAGES[1].keywords} style={{ width: '100%', borderRadius: '2%' }} src={PLASMAIMAGES[1].img} />

      <Typography variant="h6" gutterBottom>
        Beneficios de la sueroterapia con plaquetas
      </Typography>

      <Img alt={PLASMAIMAGES[2].keywords} style={{ width: '100%', borderRadius: '2%' }} src={PLASMAIMAGES[2].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La terapia con PRP puede ayudar a mejorar la calidad de la piel, reducir arrugas, acelerar la recuperación en lesiones musculares y promover el crecimiento del cabello en casos de alopecia.
      </Typography>
    </Container>
  );
};

export default PlateletTherapyProcess;
