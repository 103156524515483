import { Typography } from '@mui/material';
import WhatsappButton from './social-bar/WhatsAppButton';
import { CSSProperties } from 'styled-components';

const boxStyle: CSSProperties = {
  marginRight: '1vh',
  margin: '0',
  padding: '0.8vh',
  borderRadius: '0.7vh',
  background: 'linear-gradient(to right, #98FB98, #FFFFFF)',  
  
};


export default function FooterBar() {

  return (
    <footer>
      <Typography color='black' fontSize='2vh' component='div' style={boxStyle} >
        Contáctame ahora mismo
      </Typography>
      <WhatsappButton />
    </footer>);
}
