import { Container } from "@mui/material";
import { CSSProperties } from "react";
import { CDNUrl } from "./config";

const imagenSrc = `${CDNUrl}/logo/webspa.jpg`;

export default function FaceRounded() {

    const imgStyle: CSSProperties =
    {
        width: '10vh',
        height: '10vh',
        borderRadius: '50%',
        margin: '2vh 2vh 3vh 2vh', // top, right, bottom, left

    };

    const containerStyle: CSSProperties =
    {
        margin: '0vh',
        textAlign: 'center',
        display: 'flex',
        justifyItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
    }

    return (<Container style={containerStyle}>
        <img style={imgStyle} src={imagenSrc} alt="Bienvenido" />
    </Container>);
}