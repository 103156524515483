import React from 'react';
import { motion } from 'framer-motion';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CSSProperties } from 'styled-components';
import FaceRounded from './FaceRounded';

const NeonText: React.FC<{ text: string, fontName: string }> = ({ text, fontName }) => {

    const fontSize = isMobile ? '7vh' : '14vh';

    const textStyle: CSSProperties = {
        fontFamily: fontName,
        fontSize: fontSize,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        width: '100%',
        paddingTop: '0vh',
        paddingBottom: '3vh',
        display: 'block',
        position: 'relative',
        top: '2vh',
        marginBottom: '2vh'
    };

    return (
        <Typography component='div' align='center' fontSize={fontSize} className='main-title' style={textStyle}>
            <FaceRounded />
            <motion.div
                style={textStyle}
                animate={{
                    textShadow: [
                        '0.2vh 0.2vh 0.5vh rgba(121, 120, 120, 0.94), 0 0 0.8vh rgba(0, 255, 0, 0.5), 0 0 1vh rgba(0, 255, 0, 0.5)',
                        '0.2vh 0.2vh 0.5vh rgba(121, 120, 120, 0.94), 0 0 1.5vh rgba(0, 255, 0, 0.6), 0 0 2vh rgba(0, 255, 0, 0.7)',
                        '0.2vh 0.2vh 0.5vh rgba(121, 120, 120, 0.94), 0 0 1vh rgba(0, 255, 0, 0.5), 0 0 1.5vh rgba(0, 255, 0, 0.6)',
                        '0.2vh 0.2vh 0.5vh rgba(121, 120, 120, 0.94), 0 0 0.8vh rgba(0, 255, 0, 0.5), 0 0 1vh rgba(0, 255, 0, 0.5)',
                    ],
                }}
                transition={{
                    duration: 1, // Duración de 1 segundo para el ciclo completo
                    ease: 'easeInOut',
                    repeat: Infinity, // Repetición infinita
                    repeatType: 'loop', // Se repite en bucle
                }}
            >
                {text}
            </motion.div >
        </Typography>
    );
};

export default NeonText;
