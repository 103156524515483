import React from 'react';
import { motion } from 'framer-motion';

interface DiscountBannerProps {
  discountText: string; // HTML string
}

const DiscountBanner: React.FC<DiscountBannerProps> = ({ discountText }) => {
  const bannerVariants = {
    hidden: { opacity: 0, scale: 0.8, rotate: 0 },
    visible: {
      opacity: 1,
      scale: 1.1,
      rotate: [-10, 10, -10, 10, 0],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "reverse" as const
      }
    }
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.5, duration: 1 }
    }
  };

  return (
    <motion.div
      className="discount-banner"
      initial="hidden"
      animate="visible"
      variants={bannerVariants}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70vw',   // 70% del ancho de la pantalla
        height: '70vw',  // 70% del ancho para mantenerlo circular
        maxWidth: '250px', // Tamaño máximo para pantallas grandes
        maxHeight: '250px',
        background: 'linear-gradient(to right, rgb(152, 251, 152), rgb(144, 238, 144), rgb(193, 255, 193))',
        borderRadius: '50%',
        boxShadow: '0 0 20px rgba(0,0,0,0.2)',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <motion.div
        variants={textVariants}
        style={{
          fontSize: 'clamp(12px, 4vw, 36px)', // Ajuste dinámico del tamaño del texto
          textAlign: 'center',
          color: '#ffff00',
          fontWeight: 'bold',
          textShadow: '0.2vh 0.2vh #101010',
        }}
        dangerouslySetInnerHTML={{ __html: discountText }}
      />
    </motion.div>
  );
}

export default DiscountBanner;
