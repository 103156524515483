const FACIALIMAGES =
    [
        {
            rows: 1,
            cols: 1,
            title: 'Limpieza Facial Profunda con Extracción',
            description: 'Imagen que muestra el proceso de limpieza facial profunda con extracción de impurezas para mejorar la salud de la piel.',
            keywords: 'limpieza facial profunda, extracción de impurezas, tratamiento facial, cuidado de la piel, exfoliación, hidratación profunda, eliminación de puntos negros, rejuvenecimiento facial',
            img: require('./img/facial01.jpg')
        },
        {
            rows: 1,
            cols: 1,
            title: 'Tratamiento de Hidratación Facial Intensa',
            description: 'Imagen que ilustra la aplicación de productos para hidratar profundamente la piel durante una limpieza facial profunda.',
            keywords: 'limpieza facial profunda, hidratación facial, tratamiento de belleza, exfoliación, mascarilla hidratante, cuidado de la piel, revitalización, limpieza de poros',
            img: require('./img/facial02.jpg')
        },
        {
            rows: 1,
            cols: 1,
            title: 'Exfoliación y Limpieza Profunda del Rostro',
            description: 'Imagen que representa el proceso de exfoliación y limpieza profunda para eliminar células muertas y revitalizar la piel.',
            keywords: 'limpieza facial profunda, exfoliación facial, eliminación de células muertas, cuidado de la piel, revitalización facial, tratamiento de belleza, reducción de impurezas, mejora de la textura de la piel',
            img: require('./img/facial03.jpg')
        },
    ];

export default FACIALIMAGES;
