import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { CSSProperties } from "react";
import ReplyIcon from '@mui/icons-material/Reply';

const linkStyle: CSSProperties =
{
    display: 'flex',
    margin: '1vh',
    position: 'sticky',
    left: '0vh',
    textAlign: 'right',
    textDecoration: 'none',
    verticalAlign: 'middle',
    top: '1%',
    zIndex: 1000
};

interface params {
    url: string
}


const gradientStyle = {
    background: "linear-gradient(45deg, white 0%, palegreen 100%)",
    boxShadow: "0px 8px 20px rgba(152, 251, 152, 0.3)", // Cambié el color de la sombra a palegreen
    borderImageSlice: "1",
    borderRadius: "10px",
    padding: "5px",
    color: 'black',
};



export const BackButton = (p: params) => {
    return (
        <Link style={linkStyle} to={p.url}>
            <Button variant="text" style={gradientStyle}>
                <ReplyIcon />
            </Button>
        </Link>

    );
}