const SUEROIMAGES =
    [
        {
            rows: 1,
            cols: 1,
            title: 'Terapia PRP para Rejuvenecimiento Facial',
            description: 'Imagen que muestra el proceso de aplicación de plasma rico en plaquetas para rejuvenecer la piel del rostro.',
            keywords: 'plasma rico en plaquetas, rejuvenecimiento facial, tratamiento estético, PRP facial, regeneración celular, terapia plaquetaria, reducción de arrugas, cuidado de la piel, estética facial',
            img: require('./img/suero01.jpg')
        },
        {
            rows: 1,
            cols: 1,
            title: 'Tratamiento PRP para Revitalización Capilar',
            description: 'Imagen que ilustra la aplicación de plasma rico en plaquetas para estimular el crecimiento capilar y mejorar la salud del cuero cabelludo.',
            keywords: 'plasma rico en plaquetas, antienvejecimiento, sueroterapia PRP, revitalización capilar, PRP capilar, medicina regenerativa, estimulación del crecimiento del cabello, terapia capilar',
            img: require('./img/suero02.jpg')
        },
        {
            rows: 1,
            cols: 1,
            title: 'Aplicación de PRP para Terapia Antiarrugas',
            description: 'Imagen que representa la aplicación de sueroterapia con plaquetas para reducir arrugas y mejorar la elasticidad de la piel.',
            keywords: 'plasma rico en plaquetas, tratamiento facial, sueroterapia con plaquetas, PRP rejuvenecimiento, estética avanzada, terapia antiarrugas, mejora de la elasticidad, cuidado anti-envejecimiento, terapia estética',
            img: require('./img/suero03.jpg')
        },
    ];

export default SUEROIMAGES;
