import React from 'react';
import { Typography, Container } from '@mui/material';
import { BackButton } from '../common/BackButton';
import FACIALIMAGES from './data';
import { Img } from 'react-image';

const DeepFacialCleansing = () => {
  return (
    <Container>
      <BackButton url='/' />

      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>LIMPIEZA FACIAL PROFUNDA</h3>
      </Typography>

      <Img alt={FACIALIMAGES[0].keywords} style={{ width: '100%', borderRadius: '2%' }} src={FACIALIMAGES[0].img} />

      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La limpieza facial profunda es un tratamiento diseñado para eliminar impurezas, células muertas y puntos negros, dejando la piel limpia, suave y saludable.
      </Typography>
      <Typography variant="h6" gutterBottom>
        ¿Cómo se realiza el procedimiento?
      </Typography>

      <Img alt={FACIALIMAGES[1].keywords} style={{ width: '100%', borderRadius: '2%' }} src={FACIALIMAGES[1].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        El procedimiento comienza con la apertura de los poros mediante vapor, seguido de la exfoliación para eliminar células muertas. Luego, se realiza una extracción manual de impurezas, y finalmente se aplican mascarillas específicas para cada tipo de piel.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Beneficios de la limpieza facial profunda
      </Typography>

      <Img alt={FACIALIMAGES[2].keywords} style={{ width: '100%', borderRadius: '2%' }} src={FACIALIMAGES[2].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        Este tratamiento ayuda a mantener la piel libre de impurezas, reduce el exceso de grasa, minimiza los poros dilatados y mejora la textura y luminosidad de la piel.
      </Typography>
    </Container>
  );
};

export default DeepFacialCleansing;
