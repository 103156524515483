const HIDROIMAGES =
    [
        {
            rows: 1,
            cols: 1,
            title: 'Tratamiento BabyGlow para Luminosidad Facial',
            description: 'Imagen que muestra la aplicación del tratamiento BabyGlow para lograr una piel luminosa y uniforme, ideal para mejorar el tono y la textura del rostro.',
            keywords: 'BabyGlow luminosidad facial tratamiento estético rejuvenecimiento de la piel mejora del tono uniformidad de la piel cuidado facial tratamiento glow',
            img: require('./img/hidrolipoclasia01.jpg')
        },
        {
            rows: 1,
            cols: 1,
            title: 'Aplicación de Suero BabyGlow',
            description: 'Imagen que ilustra la aplicación del suero BabyGlow, diseñado para hidratar y revitalizar la piel, proporcionando un efecto glow inmediato.',
            keywords: 'BabyGlow suero facial hidratación profunda efecto glow revitalización de la piel tratamiento facial estética avanzada cuidado de la piel',
            img: require('./img/hidrolipoclasia02.jpg')
        },       
    ];

export default HIDROIMAGES;
