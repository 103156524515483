import { isMobile } from 'react-device-detect';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import NeonText from './common/NeonText';

const fontSizeSubTitle = isMobile ? '1.3vh' : '2vh';

export function TopBar() {
    return (<Container className='title-box-background'>
        <NeonText text='Bienestar & Belleza' fontName='Tuesday Night' />
        <Typography component='div' align='center' style={{
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: '0.3vh',
            bottom: '2vh',
            color: 'black',
            fontWeight: 'bold',
            letterSpacing: '2vh',
            marginLeft: '5vh',
            marginRight: '5vh',
            padding: '0vh',
            position: 'relative',
        }} fontSize={fontSizeSubTitle}>
            BEAUTY SERVICES
        </Typography>

    </Container>);
}

