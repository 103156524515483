import React, { useState, useEffect } from 'react';
import './styles.css';
import DiscountBanner from '../common/DiscountBanner';

const Banner = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [timeLeft, setTimeLeft] = useState(10); // 10 segundos de cuenta atrás

    useEffect(() => {
        // Configura el intervalo que se ejecutará cada segundo
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);

        // Configura el timeout para ocultar el banner después de 10 segundos
        const timer = setTimeout(() => {
            setShowBanner(false);
        }, 3500); // 10 segundos

        // Limpiar el intervalo y timeout si el componente se desmonta
        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, []);

    const handleBannerClick = () => {
        setShowBanner(!showBanner);
    };

    return (
        <>
            {showBanner && (
                <div className="banner" onClick={handleBannerClick}>
                    <DiscountBanner discountText='<span>¡Oferta especial!</span> <br /> <strong>10% de descuento</strong>'></DiscountBanner>
                </div>
            )}
        </>
    );
};

export default Banner;
