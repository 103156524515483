import React from 'react';
import { Typography, Container } from '@mui/material';
import { BackButton } from '../common/BackButton';
import SUEROIMAGES from './data';
import { Img } from 'react-image';

const SerumTherapyProcedure = () => {
  return (
    <Container>
      <BackButton url='/' />

      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>SUEROTERAPIA</h3>
      </Typography>
      <Img alt={SUEROIMAGES[0].keywords} style={{ width: '100%', borderRadius: '2%' }} src={SUEROIMAGES[0].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La sueroterapia es un tratamiento que consiste en la administración intravenosa de sueros enriquecidos con vitaminas, minerales, aminoácidos y otros nutrientes esenciales para mejorar la salud y el bienestar general.
      </Typography>
      <Typography variant="h6" gutterBottom>
        ¿Cómo se realiza el procedimiento?
      </Typography>

      <Img alt={SUEROIMAGES[1].keywords} style={{ width: '100%', borderRadius: '2%' }} src={SUEROIMAGES[1].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        El procedimiento comienza con una evaluación del paciente para determinar qué tipo de suero es el más adecuado. Luego, se administra la mezcla de nutrientes directamente en el torrente sanguíneo a través de una vía intravenosa, permitiendo una absorción rápida y efectiva de los nutrientes.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Beneficios de la sueroterapia
      </Typography>

      <Img alt={SUEROIMAGES[2].keywords} style={{ width: '100%', borderRadius: '2%' }} src={SUEROIMAGES[2].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La sueroterapia ayuda a aumentar los niveles de energía, fortalecer el sistema inmunológico, mejorar la hidratación, y combatir el estrés y la fatiga. También es útil para mejorar la recuperación después de actividades físicas intensas y para tratar deficiencias nutricionales.
      </Typography>
    </Container>
  );
};

export default SerumTherapyProcedure;
