import React from 'react';
import { Typography, Container } from '@mui/material';
import { BackButton } from '../common/BackButton';
import HIDROIMAGES from './data';
import { Img } from 'react-image';

const HydrohypoclasiaProcedure = () => {
  return (
    <Container>
      <BackButton url='/' />

      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>HIDROLIPOCLASIA</h3>
      </Typography>

      <Img alt={HIDROIMAGES[0].keywords} style={{ width: '100%', borderRadius: '2%' }} src={HIDROIMAGES[0].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La hidrolipoclasia es un tratamiento estético no invasivo utilizado para reducir la grasa localizada y mejorar la apariencia corporal. Consiste en la infiltración de una solución acuosa en la zona deseada para ayudar a disolver los depósitos de grasa.
      </Typography>
      <Typography variant="h6" gutterBottom>
        ¿Cómo se realiza el procedimiento?
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        El procedimiento comienza con la aplicación de una solución fisiológica en las áreas con grasa localizada, lo que facilita la posterior disolución de las células adiposas. Posteriormente, la grasa disuelta es eliminada por el propio sistema linfático del cuerpo, ayudando a esculpir la figura.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Beneficios de la hidrolipoclasia
      </Typography>
      <Img alt={HIDROIMAGES[1].keywords} style={{ width: '100%', borderRadius: '2%' }} src={HIDROIMAGES[1].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        La hidrolipoclasia es ideal para reducir pequeños depósitos de grasa, mejorar el contorno corporal y tonificar la piel. Es un tratamiento mínimamente invasivo, seguro y con un tiempo de recuperación rápido, ideal para quienes desean resultados sin recurrir a la cirugía.
      </Typography>
    </Container>
  );
};

export default HydrohypoclasiaProcedure;
