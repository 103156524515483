import React from 'react';
import { Typography, Container } from '@mui/material';
import { BackButton } from '../common/BackButton';
import BABYGLOWIMAGES from './data';
import { Img } from 'react-image';

const BabyGlowProcedure = () => {
  return (
    <Container>
      <BackButton url='/' />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>BABYGLOW</h3>
      </Typography>

      <Img alt={BABYGLOWIMAGES[0].keywords} style={{ width: '100%', borderRadius: '2%' }} src={BABYGLOWIMAGES[0].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        BabyGlow es un tratamiento facial diseñado para mejorar la textura y el tono de la piel, dejándola con un brillo suave y juvenil. Este tratamiento está enfocado en hidratar profundamente y unificar el color de la piel.
      </Typography>
      <Typography variant="h6" gutterBottom>
        ¿Cómo se realiza el procedimiento?
      </Typography>
      <Img alt={BABYGLOWIMAGES[1].keywords} style={{ width: '100%', borderRadius: '2%' }} src={BABYGLOWIMAGES[1].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        El procedimiento de BabyGlow comienza con una limpieza profunda para preparar la piel. Luego, se aplica una mezcla de sérums y pigmentos especiales mediante una técnica de microinfusión, que ayuda a hidratar y mejorar el tono de la piel. Por último, se utiliza una mascarilla calmante para sellar el tratamiento y potenciar los resultados.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Beneficios del tratamiento BabyGlow
      </Typography>

      <Img alt={BABYGLOWIMAGES[2].keywords} style={{ width: '100%', borderRadius: '2%' }} src={BABYGLOWIMAGES[2].img} />
      <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
        Este tratamiento ayuda a unificar el tono de la piel, reduce manchas, mejora la hidratación y proporciona un brillo natural. Es ideal para quienes desean una piel suave, radiante y con un aspecto saludable sin necesidad de maquillaje.
      </Typography>
    </Container>
  );
};

export default BabyGlowProcedure;
